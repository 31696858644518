import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import autoBind from 'react-autobind';

class EpiLink extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        if (this.props.inEditMode) {
            console.log('In edit mode: ', this.props.inEditMode); // eslint-disable-line
        }
    }

    // checks if link is external or a filename
    matchUrl(url) {
        return url.match(/^(http(s)?|ftp):\/\//) || /\.[a-z0-9]*$/.test(url);
    }

    render() {
        return (
            <Fragment>
                {(this.props.inEditMode || (this.props.url && this.matchUrl(this.props.url))) ?
                    <a
                        className={this.props.className}
                        href={this.props.url}
                        target={this.props.target || null}
                        rel={this.props.target === '_blank' ? 'noopener noreferrer' : null}
                    >{this.props.children}
                    </a>
                    : <Link className={this.props.className} to={this.props.url ? this.props.url : ''}>{this.props.children}</Link>
                }
            </Fragment>
        );
    }
}

EpiLink.propTypes = {
    url: PropTypes.string,
    className: PropTypes.string,
    inEditMode: PropTypes.bool,
    children: PropTypes.any,
    target: PropTypes.string
};

const mapStateToProps = state => ({
    inEditMode: state.app.inEditMode
});

export default connect(mapStateToProps, {})(EpiLink);
