const TransportPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/transportPage/TransportPage');
const LandingPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/landingPage/LandingPage');
const SearchResultPage = () => import('./templates/searchResultPage/SearchResultPage');
const CallToActionLink = () => import(/* webpackChunkName: "DefaultComponents" */ './components/links/callToActionLink/CallToActionLink');
const AccordionList = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/accordionList/AccordionList');
const StandardMap = () => import('./components/maps/standardMap/StandardMap');
const Image = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/image/Image');
const YoutubeVideo = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/youtubeVideo/YoutubeVideo');
const HighlightedLink = () => import(/* webpackChunkName: "DefaultComponents" */ './components/links/highlightedLink/HighlightedLink');
const HighlightedLinkList = () => import(/* webpackChunkName: "DefaultComponents" */ './components/links/highlightedLinkList/HighlightedLinkList');
const DownloadButton = () => import('./components/buttons/downloadButton/DownloadButton');
const FileDownloadList = () => import('./components/links/fileDownloadList/FileDownloadList');
const StartPage = () => import(/* webpackChunkName: "StartPage" */ './templates/startPage/StartPage');
const FileExplorerBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/fileExplorerBlock/FileExplorerBlock');
const ContactFormBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/contactModule/ContactModule');
const ErrorPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/errorPage/ErrorPage');
const KnowledgeNeedsPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/knowledgeNeedsPage/KnowledgeNeedsPage');
const KnowledgeNeedInfoPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/knowledgeNeedInfoPage/KnowledgeNeedInfoPage');

export const EPI_COMPONENTS = [
    {
        id: 'HavforumStartPage',
        componentPromise: StartPage
    },
    {
        id: 'HavforumTransportPage',
        componentPromise: TransportPage
    },
    {
        id: 'HavforumTransportPage',
        componentPromise: TransportPage
    },
    {
        id: 'HavforumLandingPage',
        componentPromise: LandingPage
    },
    {
        id: 'SearchPage(1)',
        componentPromise: SearchResultPage
    },
    {
        id: 'CallToActionLinkBlock',
        componentPromise: CallToActionLink
    },
    {
        id: 'AccordionListBlock',
        componentPromise: AccordionList
    },
    {
        id: 'StandardMapBlock',
        componentPromise: StandardMap
    },
    {
        id: 'ImageBlock',
        componentPromise: Image
    },
    {
        id: 'YouTubeVideoBlock',
        componentPromise: YoutubeVideo
    },
    {
        id: 'HighlightedLinkBlock',
        componentPromise: HighlightedLink
    },
    {
        id: 'HighlightedLinkListBlock',
        componentPromise: HighlightedLinkList
    },
    {
        id: 'DownloadButtonBlock',
        componentPromise: DownloadButton
    },
    {
        id: 'FileDownloadListBlock',
        componentPromise: FileDownloadList
    },
    {
        id: 'FileExplorerBlock',
        componentPromise: FileExplorerBlock
    },
    {
        id: 'ContactForm',
        componentPromise: ContactFormBlock
    },
    {
        id: 'HavforumErrorPage',
        componentPromise: ErrorPage
    },
    {
        id: 'HavforumKnowledgeNeedsPage',
        componentPromise: KnowledgeNeedsPage
    },
    {
        id: 'HavforumKnowledgeNeedInfoPage',
        componentPromise: KnowledgeNeedInfoPage
    }
];

export default EPI_COMPONENTS;
