import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { translations } from '../../../sharedJs/translations';
import EpiLink from '../../links/epiLink/EpiLink';
import NavMenu from '../../navMenu/NavMenu';
import BreadCrumbs from '../../breadCrumbs/BreadCrumbs';
import SvgElement from '../../uiElements/svgElement/SvgElement';
import LoadingSpinner from '../../uiElements/loadingSpinner/LoadingSpinner';
import HeaderSearchField from '../../headerSearchField/HeaderSearchField';
import { bulkSetAttribute } from '../../../sharedJs/utilityFunctions';

import '../../../styles/_utilities.scss';
import '../../../styles/_grid.scss';
import './_header.scss';

import menuIconWhite from '../../../assets/icons/icon-menu-white.svg';
import logo from '../../../assets/logo/logo-bolger-blaa.svg';
import logoTextEn from '../../../assets/logo/Logotekst_engelsk.svg';
import logoTextNo from '../../../assets/logo/Logotekst_norsk.svg';
import menuIconBlue from '../../../assets/icons/icon-menu-blue.svg';
import closeIconBlue from '../../../assets/icons/icon-close-blue.svg';
import searchIconBlue from '../../../assets/icons/icon-search-blue.svg';
import closeBlack from '../../../assets/icons/icon-close-black.svg';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navMenuIsOpen: false,
            mobileSearchIsOpen: false,
            closeMobileSearch: false
        };
        autoBind(this);
        this.mobileSearchPanelRef = React.createRef();
        this.pathToSearchPage = '/sok/';
    }

    componentDidUpdate(prevProps) {
        if ((this.props.location && prevProps.location) && (this.props.location.pathname !== prevProps.location.pathname)) {
            this.setState({ navMenuIsOpen: false, mobileSearchIsOpen: false, closeMobileSearch: false }); // eslint-disable-line
        }
    }

    getHeaderClassName() {
        let cN = '';
        if (this.props.location && this.isFrontPage(this.props.location.pathname)) {
            cN = `${this.props.className}--havforum-frontpage`;
        }
        return `${this.props.className} ${this.props.className}--havforum ${cN}`;
    }

    getLogo(isDesktop) {
        if (isDesktop) {
            return this.props.language.name === 'en' ? logoTextEn : logoTextNo;
        }
        return this.props.language.name === 'en' ? logoTextEn : logoTextNo;
    }

    getIcon(icon) {
        switch (icon) {
        case 'hamburger':
            return menuIconBlue;
        case 'close':
            return closeIconBlue;
        case 'search':
            return searchIconBlue;
        default:
            return null;
        }
    }

    setMobileSearchPanelState(open) {
        const aTags = document.getElementsByTagName('a');
        const buttons = document.getElementsByTagName('button');
        const inputs = document.getElementsByTagName('input');
        const textareas = document.getElementsByTagName('textarea');
        const selectTags = document.getElementsByTagName('select');

        if (open) {
            bulkSetAttribute({
                items: [...aTags, ...buttons, ...inputs, ...textareas, ...selectTags],
                attr: 'tabIndex',
                value: '-1'
            });
            this.setState({ mobileSearchIsOpen: true });
            document.documentElement.style.overflow = 'hidden';
        } else {
            this.setState({ closeMobileSearch: true });
            setTimeout(() => {
                this.setState({ mobileSearchIsOpen: false, closeMobileSearch: false });
                bulkSetAttribute({
                    items: [...aTags, ...buttons, ...inputs, ...textareas, ...selectTags],
                    attr: 'tabIndex',
                    value: '0'
                });
            }, 700);
            document.documentElement.style.overflow = 'scroll';
        }
    }

    focusHandler() {
        if (!this.state.navMenuIsOpen) {
            this.setState({ navMenuIsOpen: true });
        }
    }

    toggleNavMenu() {
        window.scrollTo(0, 0);
        this.setState({
            navMenuIsOpen: !this.state.navMenuIsOpen
        });
    }

    isSearchPage(pathname) {
        if (pathname && (pathname.toString() === '/sok/' || pathname.toString() === '/en/search/' || pathname.toString() === '/en/sok/')) {
            return true;
        }
        return false;
    }

    isFrontPage(pathname) {
        if (pathname && (pathname === '/' || pathname === '/en' || pathname === '/en/')) {
            return true;
        }
        return false;
    }

    renderSuggestions(showOnMobile, translate) {
        if (this.props.searchIsLoading) {
            return (
                <div className={`c_search-field-suggestions${showOnMobile ? ' c_search-field-suggestions--small-screen' : ' u_hidden--mobile'}`}>
                    <ul className="c_search-field-suggestions__list">
                        <li className="c_search-field-suggestions__list-item" key="suggestionKey__loading-spinner">
                            <LoadingSpinner />
                        </li>
                    </ul>
                </div>
            );
        }
        if (this.props.searchSuggestions.length > 0) {
            return (
                <div className={`c_search-field-suggestions${showOnMobile ? ' c_search-field-suggestions--small-screen' : ' u_hidden--mobile'}`}>
                    <ul className="c_search-field-suggestions__list">
                        {this.props.searchSuggestions.map(item => (
                            <li
                                className="c_search-field-suggestions__list-item"
                                key={`suggestionKey__${item.title}_${item.url}`}
                            >
                                <EpiLink url={item.url}>
                                    <span className="name">{item.title}</span>
                                </EpiLink>
                            </li>
                        ))}
                        <li className="c_search-field-suggestions__list-item" key="suggestionKey__toSearchPage">
                            <EpiLink url={`${this.pathToSearchPage}?searchString=${this.props.searchInputValue}`}>
                                <span className="name">{`${translate.App.ShowAllHitsOn} "${this.props.searchInputValue}"`}</span>
                            </EpiLink>
                        </li>
                    </ul>
                </div>
            );
        }
        return null;
    }

    render() {
        const { pathname } = this.props.location;
        let currentLevel = null;
        const homePath = this.props.language.name === 'no' ? '/' : `/${this.props.language.name}/`;

        const translate = translations(this.props.language.name);
        const {
            header
        } = this.props.epiDataModel;

        if (header && header.menu && header.menu.length > 0) {
            if (this.isFrontPage(pathname) || this.isSearchPage(pathname)) {
                currentLevel = header.menu.find(item => item.pageName === '');
            } else {
                currentLevel = header.menu.find(item => (item.url.toLowerCase() === pathname.toString().toLowerCase()));
            }
        }

        if (header) {
            this.pathToSearchPage = header.searchPage;
        }

        return (
            <header
                className={`${this.getHeaderClassName()} ${this.isFrontPage(pathname) ? `${this.props.className}--front-page` : ''}`}
            >
                <a
                    href="#content"
                    className="skipLink skipLink--top"
                >{translate.App.SkipToMainContent}
                </a>
                <div className="g_container g_container--full" id="headerLogo">
                    <div className={`${this.props.className}__logo-wrapper`}>
                        <EpiLink
                            url={homePath}
                            className={`${this.props.className}__home-link`}
                            aria-label={translate.App.BackToFrontPage}
                        >
                            <span className="u_visuallyhidden">{translate.App.BackToFrontPage}</span>
                            <SvgElement
                                src={logo}
                                title={translate.App.BackToFrontPage}
                                desc={translate.App.GoBackToFrontPage}
                                className={`${this.props.className}__logo ${this.props.className}__logo--mobile`}
                            />
                            <SvgElement
                                src={logo}
                                title={translate.App.BackToFrontPage}
                                desc={translate.App.GoBackToFrontPage}
                                className={`${this.props.className}__logo ${this.props.className}__logo--desktop`}
                            />
                        </EpiLink>
                        <EpiLink
                            url={homePath}
                            aria-label={translate.App.BackToFrontPage}
                        >
                            <div className={`${this.props.className}__logo ${this.props.className}__logo--desktop ${this.props.className}__logo-wrapper--text`}>
                                <SvgElement
                                    src={this.getLogo(true)}
                                    title={translate.App.BackToFrontPage}
                                    desc={translate.App.GoBackToFrontPage}
                                    className={`${this.props.className}__logo ${this.props.className}__logo--desktop`}
                                />
                            </div>
                            <div className={`${this.props.className}__logo ${this.props.className}__logo--mobile ${this.props.className}__logo-wrapper--text`}>
                                <SvgElement
                                    src={this.getLogo(false)}
                                    title={translate.App.BackToFrontPage}
                                    desc={translate.App.GoBackToFrontPage}
                                    className={`${this.props.className}__logo ${this.props.className}__logo--mobile`}
                                />
                            </div>
                        </EpiLink>
                        <div className={`${this.props.className}__content-wrapper`}>
                            <div className={`${this.props.className}__actions-wrapper`}>
                                <button
                                    className="c_mobile-header-search-button u_hidden--desktop"
                                    aria-label={translate.App.Search}
                                    aria-expanded={this.state.mobileSearchIsOpen}
                                    onClick={() => this.setMobileSearchPanelState(true)}
                                >
                                    <span className="u_visuallyhidden">{translate.App.Search}</span>
                                    <SvgElement
                                        src={this.getIcon('search')}
                                        title={translate.App.Search}
                                        desc={translate.App.ClickToSearch}
                                    />
                                </button>
                                {!this.isSearchPage(pathname) && window.innerWidth > 768 &&
                                <HeaderSearchField
                                    className="u_hidden--mobile"
                                    id="mainHeaderSearchInput"
                                    pathToSearchPage={this.pathToSearchPage}
                                />
                                }
                                {this.props.searchInputValue && this.props.searchInputValue.length > 0 &&
                                !this.isSearchPage(pathname) &&
                                this.renderSuggestions(false, translate)
                                }
                                <button
                                    onClick={() => this.toggleNavMenu()}
                                    className="c_nav-toggle-button"
                                    aria-label={translate.App.Menu}
                                    aria-expanded={this.state.navMenuIsOpen}
                                    aria-controls="masterNavMenu"
                                >
                                    <span className="c_nav-toggle-button__text u_hidden--mobile">
                                        {translate.App.Menu}
                                    </span>
                                    {this.state.navMenuIsOpen ?
                                        <SvgElement
                                            src={this.getIcon('close')}
                                            title={translate.App.CloseMenu}
                                            desc={translate.App.ButtonToCloseMenu}
                                            className="c_nav-toggle-button__icon c_nav-toggle-button__icon--close-menu u_hidden--desktop"
                                        />
                                        :
                                        <SvgElement
                                            src={this.getIcon('hamburger')}
                                            title={translate.App.OpenMenu}
                                            desc={translate.App.ButtonToOpenMenu}
                                            className="c_nav-toggle-button__icon u_hidden--desktop"
                                        />
                                    }
                                    <SvgElement
                                        src={menuIconWhite}
                                        title={translate.App.OpenMenu}
                                        desc={translate.App.ButtonToOpenMenu}
                                        className="c_nav-toggle-button__icon u_hidden--mobile"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {!this.isFrontPage(pathname) && !this.isSearchPage(pathname) && window.innerWidth > 768 &&
                    this.props.epiDataModelLoaded && this.props.epiDataModel.header &&
                    <div className="g_container g_container--full" id="">
                        <BreadCrumbs breadcrumbs={header.breadCrumbs} translate={translate} />
                    </div>
                }

                {this.props.epiDataModelLoaded && this.props.epiDataModel.header &&
                this.props.epiDataModel.header.menu && currentLevel &&
                <NavMenu
                    isOpen={this.state.navMenuIsOpen}
                    closeMenu={this.toggleNavMenu}
                    location={this.props.location}
                    focusHandler={this.focusHandler}
                    currentLevel={currentLevel}
                />
                }
                {this.state.mobileSearchIsOpen &&
                <div
                    ref={this.mobileSearchPanelRef}
                    className={`c_mobile-search-panel u_hidden--desktop${this.state.closeMobileSearch ?
                        ' c_mobile-search-panel--close' : ''}`}
                >
                    <button
                        className="c_close-button"
                        onClick={() => this.setMobileSearchPanelState(false)}
                        aria-expanded={this.state.mobileSearchIsOpen}
                    >
                        <span className="c_close-button__inner-wrapper">
                            <span className="c_close-button__text">{translate.App.Close}</span>
                            <SvgElement
                                src={closeBlack}
                                title={translate.App.CloseSearch}
                                desc={translate.App.ButtonToCloseSearch}
                            />
                        </span>
                    </button>
                    <HeaderSearchField
                        className="c_search-field--small-screen u_hidden--desktop"
                        id="mainMobileHeaderSearchInput"
                        pathToSearchPage={this.pathToSearchPage}
                        alwaysFullWidth
                    />
                    {this.props.searchInputValue && this.props.searchInputValue.length > 0 &&
                    this.renderSuggestions(true, translate)
                    }
                </div>
                }
            </header>
        );
    }
}

Header.propTypes = {
    className: PropTypes.string.isRequired,
    location: PropTypes.object,
    searchInputValue: PropTypes.string,
    searchSuggestions: PropTypes.array,
    language: PropTypes.object,
    epiDataModel: PropTypes.object,
    epiDataModelLoaded: PropTypes.bool,
    searchIsLoading: PropTypes.bool
};

Header.defaultProps = {
    searchInputValue: ''
};

const mapStateToProps = state => ({
    searchInputValue: state.siteSearch.inputValue,
    searchSuggestions: state.siteSearch.searchSuggestions,
    searchIsLoading: state.siteSearch.isLoading,
    language: state.app.language,
    epiDataModel: state.app.epiDataModel,
    epiDataModelLoaded: state.app.epiDataModelLoaded
});

export default connect(mapStateToProps, {})(Header);
