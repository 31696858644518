import React from 'react';
import PropTypes from 'prop-types';
import EpiLink from '../links/epiLink/EpiLink';
import SvgElement from '../uiElements/svgElement/SvgElement';

import arrowRight from '../../assets/icons/icon-arrow-right-black.svg';

import './_breadcrumbs.scss';

const BreadCrumbs = props => (
    <div className="c_header-breadcrumbs u_hidden--mobile">
        <nav aria-label="Brødsmulesti">
            <ul>
                {props.breadcrumbs.map((crumb, index) => {
                    if (index === props.breadcrumbs.length - 1) {
                        return (
                            <li
                                key={`breadcrumb_key__${crumb.id}`}
                                className="c_header-breadcrumbs__item"
                            >
                                {crumb.text}
                            </li>
                        );
                    }
                    return (
                        <li
                            key={`breadcrumb_key__${crumb.id}`}
                            className="c_header-breadcrumbs__item"
                        >
                            <EpiLink
                                url={crumb.url}
                            >
                                {index === 0 ?
                                    <span>{props.translate.App.FrontPage}</span>
                                    :
                                    crumb.text
                                }
                            </EpiLink>
                            <SvgElement
                                src={arrowRight}
                                title="Pil høyre"
                                desc="Pil som peker til neste lenke"
                            />
                        </li>
                    );
                })}
            </ul>
        </nav>
    </div>
);

BreadCrumbs.propTypes = {
    breadcrumbs: PropTypes.array,
    translate: PropTypes.object
};

export default BreadCrumbs;
