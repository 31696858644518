import React from 'react';
import PropTypes from 'prop-types';

const SvgElement = props => (
    <svg viewBox={props.src.viewBox} aria-hidden="true" className={props.className}>
        <title>{props.title}</title>
        <desc>{props.desc}</desc>
        <use xlinkHref={`#${props.src.id}`} />
    </svg>
);

SvgElement.propTypes = {
    src: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default SvgElement;
