import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import EpiLink from '../links/epiLink/EpiLink';

import '../../styles/_utilities.scss';
import '../../styles/_grid.scss';
import './_languageMenu.scss';

class ChooseLanguage extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        return (
            <div className={`c_choose-language ${this.props.className}`}>
                <ul>
                    {this.props.menuItems.map(item => (
                        <li key={`language-menu-item___${item.pageName}`}>
                            <EpiLink
                                url={item.url}
                            >
                                {item.pageName}
                            </EpiLink>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

ChooseLanguage.propTypes = {
    className: PropTypes.string,
    menuItems: PropTypes.array
};

export default ChooseLanguage;
