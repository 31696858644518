import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { translations } from '../../../sharedJs/translations';
import EpiLink from '../../links/epiLink/EpiLink';
import ContributorsBlock from '../../havforumComponents/contributorsBlock/ContributorsBlock';
import LanguageMenu from '../../navMenu/LanguageMenu';

import '../../../styles/_utilities.scss';
import '../../../styles/_grid.scss';
import './_footer.scss';

class Footer extends Component {
    constructor() {
        super();
        autoBind(this);
    }

    onLanguageChange(event) {
        this.props.changeLanguage(event.target.value);
        if (event.target.value === 'en') {
            window.location = '/en/';
        } else {
            window.location = '/';
        }
        document.getElementsByTagName('html')[0].setAttribute('lang', event.target.value);
    }

    getClassName() {
        return 'c_master-footer c_master-footer--miljostatus';
    }

    getMultilineText(text) {
        const match = /\n/.exec(text);
        if (match) {
            return text.split('\n').map(i =>
                <span key={`multiLineKey_${i}`}>{i}<br /></span>);
        }
        return <span>{text}</span>;
    }

    render() {
        // TODO: Change document.location.pathname below with pageUrl
        if (!this.props.epiDataModel || !this.props.epiDataModelLoaded) {
            return null;
        }
        const {
            contributors,
            contributorsPageUrl
        } = this.props.epiDataModel;

        const {
            footerLinks = null,
            privacyLinks = null
        } = this.props.epiDataModel.footer;

        const translate = translations(this.props.language.name);

        return (
            <footer className={this.getClassName()} key={document.location.pathname}>
                {contributors && contributors.length > 0 &&
                <ContributorsBlock
                    heading={translate.App.ContributorsToHavforum}
                    contributors={contributors}
                    readMoreUrl={contributorsPageUrl}
                    translate={translate}
                />
                }

                <div className="c_master-footer-content">
                    <div className="g_container g_container--full">
                        <div className="g_row g_row--top">
                            <div className="g_col c_master-footer__links-wrapper">
                                <div className="g_col">
                                    <ul className="c_master-footer__link-list c_master-footer__link-list--main">
                                        {footerLinks && footerLinks.map(link => (
                                            <li key={`linkList_key__${link.name}`}>
                                                <EpiLink url={link.url}>{link.name}</EpiLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="g_row c_master-footer__privacy-row">
                            <div className="g_col g_col--large-12">
                                <h2 className="u_visuallyhidden u_hide-from-anchor-menu">{translate.App.Privacy}</h2>
                                {privacyLinks && privacyLinks.length > 0 &&
                                <ul className="c_master-footer__privacy-links">
                                    {privacyLinks.map(link => (
                                        <li key={`privacyLinkList_key__${link.name}`}>
                                            <EpiLink url={link.url}>{link.name}</EpiLink>
                                        </li>
                                    ))}
                                </ul>
                                }

                                {this.props.epiDataModelLoaded && this.props.epiDataModel.footer.languageMenu
                                    && this.props.epiDataModel.footer.languageMenu.length > 1 && (
                                    <LanguageMenu
                                        className=""
                                        menuItems={this.props.epiDataModel.footer.languageMenu}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <a
                    href="#content"
                    className="skipLink skipLink--bottom"
                >{translate.App.SkipToMainContent}
                </a>
            </footer>
        );
    }
}

Footer.propTypes = {
    changeLanguage: PropTypes.func,
    language: PropTypes.object,
    // selectedLanguage: PropTypes.string,
    epiDataModel: PropTypes.object,
    epiDataModelLoaded: PropTypes.bool
};

const mapStateToProps = state => ({
    language: state.app.language,
    epiDataModel: state.app.epiDataModel,
    epiDataModelLoaded: state.app.epiDataModelLoaded
});

export default connect(mapStateToProps, {})(Footer);
