import React from 'react';
import PropTypes from 'prop-types';
import { translations } from '../../sharedJs/translations';
import EpiLink from '../links/epiLink/EpiLink';
import SvgElement from '../uiElements/svgElement/SvgElement';
import arrowRight from '../../assets/icons/icon-arrow-right-black.svg';

const TopListItem = (props) => {
    const translate = translations(props.language.name);
    if (props.active && !props.data.hasChildren) {
        return (
            <li
                className={`c_nav-list__item c_nav-list__item--level-block${props.topLevelBlock}`}
            >
                <div className="c_nav-list__item-content c_nav-list__item-content--active">
                    <span className="u_font-weight-medium">{props.data.pageName}</span>
                </div>
            </li>
        );
    }
    return (
        <li
            className={`c_nav-list__item c_nav-list__item--level-block${props.topLevelBlock}`}
        >
            <EpiLink className="c_nav-list__item-content" url={props.data.url} onFocus={() => props.focusHandler()}>
                <span className={props.active ? 'u_font-weight-medium' : ''}>{props.data.pageName}</span>
                <SvgElement
                    src={arrowRight}
                    title={`${translate.App.GoTo} ${props.data.pageName}`}
                    desc={translate.App.ArrowRight}
                    className="c_nav-list-item__arrow-right"
                />
            </EpiLink>
        </li>
    );
};

TopListItem.propTypes = {
    data: PropTypes.object.isRequired,
    active: PropTypes.bool,
    focusHandler: PropTypes.func,
    topLevelBlock: PropTypes.number,
    language: PropTypes.object
};

TopListItem.defaultProps = {
    active: false
};

export default TopListItem;
