import React, { Component } from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import api from './api/api';
import { updateModelByUrl, setEpiModelLoading } from './actions/index';
import { setInputValue, setHeaderInputExpandedState } from './actions/sitesearchActions';
import Header from './components/contentBlocks/header/Header';
import Footer from './components/contentBlocks/footer/Footer';
import PageComponentSelector from './PageComponentSelector';

import store from './store';

import './styles/main.scss';
import './styles/_grid.scss';

// `epiMessages` does not export anything but registers the `beta/contentSaved`
// and `beta/epiReady` message handlers that updates the vuex store.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#Import_a_module_for_its_side_effects_only
import './epiMessages'; // eslint-disable-line

class App extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.onRouteChange(this.props.history.location);
        store.subscribe(() => null);
        this.state = {
            lang: '-1',
            metaTags: {}
        };
    }

    componentDidMount() {
        this.props.history.listen(this.onRouteChange);
        document.getElementById('__SVG_SPRITE_NODE__').setAttribute('aria-hidden', 'true');
    }

    onRouteChange(route) {
        const parameters = {
            expand: '*'
        };

        if (store.getState().app.epiDataModel.url !== route.pathname) {
            window.scroll(0, 0);
            store.dispatch(setEpiModelLoading(true));
            api.getContentByFriendlyUrl(route.pathname + route.search, parameters).then((response) => {
                this.setMetaTags(response.data.metaTags);
                store.dispatch(updateModelByUrl(response.data));
                document.body.style.overflow = 'auto';
            });
        }
        document.documentElement.style.overflow = 'auto';
        store.dispatch(setInputValue('', false));
        store.dispatch(setHeaderInputExpandedState(false));
    }

    onLanguageChange(chosenLanguage) {
        this.setState({ lang: chosenLanguage });
    }

    setMetaTags(tags) {
        this.setState({
            metaTags: tags
        });
    }

    render() {
        return (
            <Provider store={store}>
                <div className="app-wrapper">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{this.state.metaTags.title}</title>
                        <meta property="description" content={this.state.metaTags.description} />
                        <meta property="og:title" content={this.state.metaTags.ogTitle} />
                        <meta property="og:description" content={this.state.metaTags.ogDescription} />
                        <meta property="og:type" content={this.state.metaTags.ogType} />
                        <meta property="og:url" content={this.state.metaTags.ogUrl} />
                        <meta property="og:locale" content={this.state.metaTags.ogLocale} />
                        <meta property="og:site_name" content={this.state.metaTags.ogSiteName} />
                        <meta property="og:image" content={this.state.metaTags.ogImageUrl} />
                        <meta property="ROBOTS" content={this.state.metaTags.robots} />
                    </Helmet>
                    <Header
                        location={this.props.history.location}
                        className="c_master-header"
                    />
                    <Route
                        path="*"
                        component={PageComponentSelector}
                    />
                    <Footer
                        changeLanguage={lang => this.onLanguageChange(lang)}
                        history={this.props.history}
                        selectedLanguage={this.state.lang}
                    />
                </div>
            </Provider>
        );
    }
}

App.propTypes = {
    history: PropTypes.object
};

export default withRouter(App);

