import React from 'react';
import PropTypes from 'prop-types';
import { translations } from '../../sharedJs/translations';
import EpiLink from '../links/epiLink/EpiLink';
import SvgElement from '../uiElements/svgElement/SvgElement';
import warningIcon from '../../assets/icons/icon-varsel.svg';
import arrowRight from '../../assets/icons/icon-arrow-right-black.svg';
import redirectIcon from '../../assets/icons/icon-redirect-black.svg';


const SubListItem = (props) => {
    const translate = translations(props.language.name);
    return (
        <li className="c_nav-list__item c_nav-list__item--sub-level">
            {props.data.url.length > 0 ?
                <div>
                    {props.active ?
                        <div className="c_nav-list__item-content c_nav-list__item-content--active">
                            {props.data.isWarningPage &&
                            <SvgElement
                                src={warningIcon}
                                title={translate.App.Warning}
                                desc={translate.App.WarningIconWithExclamation}
                                className="c_nav-list-item__warning-icon"
                            />
                            }
                            <span className="u_font-weight-bold">{props.data.pageName}</span>
                            <span className="u_visuallyhidden"> {translate.App.YouAreOnThisPageNow}</span>
                        </div>
                        :
                        <EpiLink
                            className="c_nav-list__item-content"
                            url={props.data.url}
                            onFocus={() => props.focusHandler()}
                        >
                            {props.data.isWarningPage &&
                            <SvgElement
                                src={warningIcon}
                                title={translate.App.Warning}
                                desc={translate.App.WarningIconWithExclamation}
                                className="c_nav-list-item__warning-icon"
                            />
                            }
                            <span>{props.data.pageName}</span>
                            {!props.data.isExternalLinkPage &&
                            <SvgElement
                                src={arrowRight}
                                title={`${translate.App.GoTo} ${props.data.pageName}`}
                                desc={translate.App.ArrowRight}
                                className="c_nav-list-item__arrow-right"
                            />
                            }
                            {props.data.isExternalLinkPage &&
                            <SvgElement
                                src={redirectIcon}
                                title={translate.App.ContentIsOnOldSite}
                                desc={translate.App.ClickToOpen}
                                className="c_nav-list-item__redirect-icon"
                            />
                            }
                        </EpiLink>
                    }
                </div>
                :
                <div>
                    <button onClick={() => props.changeLevelToShow(props.data.id)} onFocus={() => props.focusHandler()}>
                        <span className={props.active ? 'u_font-weight-medium' : ''}>{props.data.pageName}</span>
                        <SvgElement
                            src={arrowRight}
                            title={`${translate.App.GoTo} ${props.data.pageName}`}
                            desc={translate.App.ArrowRight}
                            className="c_nav-list-item__arrow-right"
                        />
                    </button>
                </div>
            }
        </li>
    );
};

SubListItem.propTypes = {
    data: PropTypes.object.isRequired,
    changeLevelToShow: PropTypes.func.isRequired,
    active: PropTypes.bool,
    focusHandler: PropTypes.func,
    language: PropTypes.object
};

SubListItem.defaultProps = {
    active: false
};

export default SubListItem;
