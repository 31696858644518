let serverSide = false;

if (typeof window === 'undefined') {
    serverSide = true;
}

export const stripStringForHtml = (usersString) => {
    const doc = new DOMParser().parseFromString(usersString, 'text/html');
    return doc.body.textContent || '';
};

export const stringToSlug = text => text.replace(/\s+/g, '-').replace(/[{()}]/g, '').replace(/--/g, '-').toLowerCase();

export const isKeyPressed = (event, key) => {
    if (event.defaultPrevented) {
        return false; // Should do nothing if the default action has been cancelled
    }

    let handled = false;
    if (event.key !== undefined && event.key === key) {
        // Handle the event with KeyboardEvent.key and set handled true.
        handled = true;
    } else if (event.keyIdentifier !== undefined && event.keyIdentifier === key) {
        // Handle the event with KeyboardEvent.keyIdentifier and set handled true.
        handled = true;
    } else if (event.keyCode !== undefined && event.keyCode === key) {
        // Handle the event with KeyboardEvent.keyCode and set handled true.
        handled = true;
    }

    if (handled) {
        // Suppress "double action" if event handled
        event.preventDefault();
    }

    return handled;
};

const base = {
    params: ('URLSearchParams' in window) ? new URLSearchParams(window.location.search) : ['', '']
};

/**
 * @desc url object for manipulating url
 * @param searchParams - getters and setters for url search params
 */
export const url = {
    searchParams: {
        get: ('URLSearchParams' in window) ?
            param => base.params.get(param)
            : null,
        set: ('URLSearchParams' in window) ?
            (param, value) => base.params.set(param, value)
            : null,
        update: ('URLSearchParams' in window) ?
            () => window.history.pushState({}, '', `#/klimagass${window.location.pathname}?${base.params}`)
            : null,
        delete: ('URLSearchParams' in window) ?
            param => base.params.delete(param)
            : null,
        setAndUpdate: ('URLSearchParams' in window) ?
            (param, value) => {
                base.params.set(param, value);
                return window.history.pushState({}, '', `#/klimagass${window.location.pathname}?${base.params}`);
            } : null,
        deleteAndUpdate: ('URLSearchParams' in window) ?
            (param) => {
                base.params.delete(param);
                return window.history.pushState({}, '', `#/klimagass${window.location.pathname}?${base.params}`);
            } : null
    }
};

export const numberFormat = (props) => {
    const {
        num,
        format = 'no-NO'
    } = props;
    if (num === null) {
        return '';
    }
    try {
        if ('Intl' in window && 'NumberFormat' in window.Intl) {
            return new Intl.NumberFormat(format, { maximumSignificantDigits: 3 }).format(num);
        }
        if (num % 1) {
            return Number.parseFloat(num).toFixed(2).replace('.', ',');
        }
        return num;
    } catch (e) {
        return num;
    }
};

export const b64 = {
    encode: ('btoa' in window) ? param => btoa(param) : param => param,
    decode: ('atob' in window) ? param => atob(param) : param => param
};

export const subscriptMapper = (text) => {
    if (!text) {
        return text;
    }

    const arr = [
        {
            text: ['co2', 'Co2', 'cO2', 'CO2'],
            subscript: 'CO₂'
        }, {
            text: ['ch4', 'Ch4', 'cH4', 'CH4'],
            subscript: 'CH₄'
        }, {
            text: ['n2o', 'N2o', 'n2O', 'N2O'],
            subscript: 'N₂O'
        }, {
            text: ['nox', 'nOx', 'Nox', 'nOX', 'NOx', 'noX', 'NOX'],
            subscript: 'NOₓ'
        }, {
            text: ['km2', 'KM2'],
            subscript: 'km²'
        }, {
            text: ['m3', 'M3'],
            subscript: 'm³'
        }
    ];
    let out = text.toString();
    for (let i = 0; i < arr.length; i++) {
        for (let x = 0; x < arr[i].text.length; x++) {
            out = out.replace(new RegExp(arr[i].text[x], 'g'), arr[i].subscript);
        }
    }
    return out;
};

const isIE = serverSide || (false || !!document.documentMode);
const isChrome = serverSide || (!!window.chrome && !!window.chrome.webstore);
const isOpera = serverSide || ((!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0);

export const browserDetection = {
    isOpera,
    // Firefox 1.0+
    isFirefox: serverSide || typeof InstallTrigger !== 'undefined',
    // Safari 3.0+ "[object HTMLElementConstructor]"
    isSafari: serverSide || (/constructor/i.test(window.HTMLElement)
        || (p => p.toString() === '[object SafariRemoteNotification]')(!window.safari
            || (typeof safari !== 'undefined' && window.safari.pushNotification))),
    // Internet Explorer 6-11 /*@cc_on!@*/
    isIE,
    // Edge 20+
    isEdge: serverSide || (!isIE && !!window.StyleMedia),
    isChrome,
    // Blink engine detection
    isBlink: serverSide || ((isChrome || isOpera) && !!window.CSS)
};

export const deepClone = arr => JSON.parse(JSON.stringify(arr));

export const isNumeric = value => Number(parseFloat(value)) === value;

const getUrlVars = (url) => {
    const vars = {};
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        vars[key] = value;
    });
    return vars;
};
export const getUrlParameter = (url, parameter, defaultvalue) => {
    let urlparameter = defaultvalue;
    if (url.indexOf(parameter) > -1) {
        urlparameter = getUrlVars(url)[parameter];
    }
    return urlparameter;
};
export const getUrlParam = (parameter, defaultvalue) => getUrlParameter(window.location.href, parameter, defaultvalue);

export const updateUrlParam = (url, param, paramVal) => {
    let newAdditionalURL = '';
    let tempArray = url.split('?');
    const baseURL = tempArray[0];
    const additionalURL = tempArray[1];
    let temp = '';
    if (additionalURL) {
        tempArray = additionalURL.split('&');
        for (let i = 0; i < tempArray.length; i++) {
            if (tempArray[i].split('=')[0] !== param) {
                newAdditionalURL += temp + tempArray[i];
                temp = '&';
            }
        }
    }
    const rowsTxt = `${temp}${param}=${paramVal}`;
    return `${baseURL}?${newAdditionalURL}${rowsTxt}`;
};

export const bulkSetAttribute = (props) => {
    const {
        items,
        attr,
        value
    } = props;
    return items.map(item => item.setAttribute(attr, value));
};

export const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export const chartDataToTable = (props) => {
    const {
        data,
        label,
        chartType
    } = props;
    const chartData = data;
    const tableData = {
        tableHeading: label,
        hideTableHeading: true,
        columns: []
    };

    const leftColObj = {
        heading: '',
        values: chartData.map(trace => trace.name)
    };

    tableData.columns.push(leftColObj);

    if (chartType === 'HorizontalBarChart') {
        const rows = [];
        for (let i = 0; i < chartData.length; i++) {
            for (let currentHeading = 0; currentHeading < chartData[i].y.length; currentHeading++) {
                const heading = chartData[i].y[currentHeading];

                if (!rows.find(h => h.heading === heading)) {
                    rows.push({
                        heading,
                        values: []
                    });
                }

                rows.find(h => h.heading === heading).values.push(chartData[i].x[currentHeading]);
            }
        }
        tableData.columns = tableData.columns.concat(rows);
    } else {
        let longest = chartData[0].x.length;
        let longestSeries = 0;
        for (let index = 0; index < chartData.length; index++) {
            if (chartData[index].x.length > longest) {
                longest = chartData[index].x.length;
                longestSeries = index;
            }
        }

        for (let i = 0; i < chartData[longestSeries].x.length; i++) {
            const yearObj = {
                heading: chartData[longestSeries].x[i],
                values: chartData.map(trace => trace.y[i])
            };
            tableData.columns.push(yearObj);
        }
        return tableData;
    }
    return tableData;
};

/**
 * @desc maps graph data for populating graph components
 * @param array $array - the array to be transformed
 * @return array - { name, x, y }
 */
export const tableDataToChart = (props) => {
    const {
        type,
        data
    } = props;

    switch (type) {
    case 'HorizontalBarChart': {
        const labels = data[0].values.map(label => label);
        const rest = labels.map((label, index) => ({
            heading: label,
            values: data.slice(1).map(item => item.values[index])
        }));
        const preparedData = [{
            heading: 'Utslippkilde',
            values: data.slice(1).map(item => item.heading)
        }, ...rest];

        const source = preparedData; // data

        const headings = deepClone(source[0].values);
        const out = headings.map((col, index) => ({
            name: subscriptMapper(headings[index]),
            y: source.slice(1).map(title => subscriptMapper(title.heading)),
            x: source.slice(1).map(item => subscriptMapper(item.values[index]))
        }));
        return out;
    }
    case 'DonutChart': {
        const headings = deepClone(data[0].values);
        const out = headings.map(() => ({
            x: headings.map(item => subscriptMapper(item)),
            y: deepClone(data[1].values)
        }));
        return out.shift();
    }
    default: {
        // Applys to 'barChart', 'lineChart', 'barChartWithLine'
        const headings = deepClone(data[0].values);
        const out = headings.map((col, index) => ({
            name: subscriptMapper(headings[index]),
            x: data.slice(1).map(title => subscriptMapper(title.heading)),
            y: data.slice(1).map(item => subscriptMapper(item.values[index]))
        }));
        return out;
    }
    }
};

export const isTouchDevice = () => {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = query => window.matchMedia(query).matches;

    if (('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch)) { // eslint-disable-line
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
};

export const hexToRGB = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r},${g},${b},${alpha})`;
    }

    return `rgba(${r},${g},${b})`;
};
