import React from 'react';
import PropTypes from 'prop-types';
import SvgElement from '../../uiElements/svgElement/SvgElement';

// import mstatusIconPlaceholder from '../../../assets/logo/logo_miljostatus.svg';
import logoMiljodir from '../../../assets/icons/logo_bw_miljodirektoratet_hvit.svg';
import logoFisk from '../../../assets/icons/logo_bw_fiskeridirektoratet_hvit.svg';
import logoHav from '../../../assets/icons/logo_bw_havforskningsinstituttet_hvit.svg';
import logoKart from '../../../assets/icons/logo_bw_kartverket_hvit.svg';
import logoKyst from '../../../assets/icons/logo_bw_kystverket_hvit.svg';
import logoMat from '../../../assets/icons/logo_bw_mattilsynet.svg';
import logoPolar from '../../../assets/icons/logo_bw_norsk_polarinstitutt_hvit.svg';
import logoRiksantik from '../../../assets/icons/logo_bw_riksantikvaren.svg';
import logoStraaling from '../../../assets/icons/logo_bw_dsa.svg';
import logoOljedir from '../../../assets/icons/logo_bw_oljedir_hvit.svg';
import logoFhi from '../../../assets/icons/logo_bw_fhi_grey.svg';
import logoVegvesen from '../../../assets/icons/logo_bw_statens_vegvesen_hvit.svg';
import logoMet from '../../../assets/icons/logo_bw_met_hvit.svg';
import logoHelsedir from '../../../assets/icons/logo_bw_helsedirektoratet_hvit.svg';
import logoPtil from '../../../assets/icons/logo_bw_ptil_hvit.svg';
import logoSjofart from '../../../assets/icons/logo_bw_sjofartsdirektoratet_hvit.svg';
import logoByggkvalitet from '../../../assets/icons/logo_bw_direktoratet_for_byggkvalitet_hvit.svg';
import logoDsb from '../../../assets/icons/logo_bw_dsb_hvit.svg';
import logoNve from '../../../assets/icons/logo_bw_nve_hvit.svg';
import logoBanenor from '../../../assets/icons/logo_bw_bane_nor_hvit.svg';
import logoAvinor from '../../../assets/icons/logo_bw_avinor_hvit.svg';
import logoKlimasenter from '../../../assets/icons/logo_bw_norsk_klimaservicesenter_hvit.svg';
import logoLandbruk from '../../../assets/icons/logo_bw_landbruksdirektoratet_hvit.svg';

import logoMiljodirEng from '../../../assets/icons/logo_bw_miljodirektoratet_hvit_eng.svg';
import logoPolarEng from '../../../assets/icons/logo_bw_norsk_polarinstitutt_hvit_eng.svg';
import logoKystEng from '../../../assets/icons/logo_bw_kystverket_hvit_eng.svg';
import logoHavEng from '../../../assets/icons/logo_bw_havforskningsinstituttet_hvit_eng.svg';

import logoHavtil from '../../../assets/icons/logo_bw_havindustritilsynet_hvit.svg';
import logoHavtilEng from '../../../assets/icons/logo_bw_havindustritilsynet_hvit_eng.svg';

import '../../../styles/_grid.scss';
import './_contributorsBlock.scss';

/* eslint-disable complexity */
const getIcon = (iconName) => {
    switch (iconName) {
    case 'miljodirektoratet':
    case 'miljodirektoratet_hvit':
        return logoMiljodir;
    case 'fiskeridirektoratet':
    case 'fiskeridirektoratet_hvit':
        return logoFisk;
    case 'havforskningsinstituttet':
    case 'havforskningsinstituttet_hvit':
        return logoHav;
    case 'kystverket':
    case 'kystverket_hvit':
        return logoKyst;
    case 'kartverket':
    case 'kartverket_hvit':
        return logoKart;
    case 'mattilsynet':
        return logoMat;
    case 'norsk_polarinstitutt':
    case 'norsk_polarinstitutt_hvit':
        return logoPolar;
    case 'riksantikvaren':
    case 'riksantikvaren_hvit':
        return logoRiksantik;
    case 'dsa':
    case 'dsa_hvit':
        return logoStraaling;
    case 'oljedirektoratet_hvit':
        return logoOljedir;
    case 'ptil_hvit':
        return logoPtil;
    case 'sjofartsdirektoratet_hvit':
        return logoSjofart;
    case 'fhi':
    case 'fhi_grey':
        return logoFhi;
    case 'statens_vegvesen_grey':
    case 'statens_vegvesen_hvit':
        return logoVegvesen;
    case 'met_grey':
    case 'met_hvit':
        return logoMet;
    case 'helsedirektoratet_grey':
    case 'helsedirektoratet_hvit':
        return logoHelsedir;
    case 'direktoratet_for_byggkvalitet_hvit':
        return logoByggkvalitet;
    case 'dsb_hvit':
        return logoDsb;
    case 'nve_hvit':
        return logoNve;
    case 'bane_nor_hvit':
        return logoBanenor;
    case 'avinor_hvit':
        return logoAvinor;
    case 'norsk_klimaservicesenter_hvit':
        return logoKlimasenter;
    case 'landbruksdirektoratet_hvit':
        return logoLandbruk;
    case 'norsk_polarinstitutt_hvit_eng':
        return logoPolarEng;
    case 'miljodirektoratet_hvit_eng':
        return logoMiljodirEng;
    case 'havforskningsinstituttet_hvit_eng':
        return logoHavEng;
    case 'kystverket_hvit_eng':
        return logoKystEng;
    case 'havindustritilsynet_hvit':
        return logoHavtil;
    case 'havindustritilsynet_hvit_eng':
        return logoHavtilEng;
    default:
        return null;
    }
};

const ContributorsBlock = props => (
    <div className="c_havforum-contributors-container">
        <div className="c_havforum-contributors g_container g_container--full">
            {props.heading && props.heading.length > 0 &&
            <h2 className="c_havforum-contributors__heading u_hide-from-anchor-menu">
                {props.heading}
            </h2>
            }
            <div className="c_havforum-contributors__inner-container">
                {props.contributors.map((item) => {
                    let icon = null;

                    if (item.blockViewIcon && item.blockViewIcon.icon) {
                        icon = getIcon(item.blockViewIcon.icon);
                    }

                    return (
                        <a
                            className="c_havforum-contributors__item"
                            key={`contrLogoKey__${item.heading}`}
                            href={item.url}
                        >
                            <div className="c_havforum-contributors__item-icon">
                                {icon &&
                                    <SvgElement
                                        src={icon}
                                        title={item.heading}
                                        desc={item.heading}
                                    />
                                }
                            </div>
                            <span className="c_havforum-contributors__item-text">{item.heading}</span>
                        </a>
                    );
                })}
            </div>

            {props.readMoreUrl &&
                <div className="c_havforum-contributors__read-more">
                    <a href={props.readMoreUrl}>{props.translate.ContributorBlock.ReadMoreAbout}</a>
                </div>
            }
        </div>
    </div>
);

ContributorsBlock.propTypes = {
    heading: PropTypes.string,
    contributors: PropTypes.arrayOf(PropTypes.shape({
        heading: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        blockViewIcon: PropTypes.object
    })).isRequired,
    readMoreUrl: PropTypes.string,
    translate: PropTypes.object
};

export default ContributorsBlock;
